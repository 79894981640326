import {useParams} from "react-router";
import "./ForgotPassword.css";
import Logo from "../../assets/newfoodsafe.png";
import axios from "axios";
import {useState} from "react";


const ForgotPassword = () => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [hideButton, setHideButton] = useState(false);
    const {token} = useParams();


    const handleSubmit = async (e) => {
        e.preventDefault();

        const password = e.target.password.value;
        const repassword = e.target.repassword.value;

        const bodyToSend = {
            password: password,
            repassword: repassword,
            token: token,
        }


        if (!password.match('[^ ]{8,40}') || !repassword.match('[^ ]{8,40}')) {
            setError('Fjalëkalimi duhet të ketë minimum 8 karaktere')
        } else if (!password || !repassword) {
            setError("Të gjitha fushat jëne te nevojshme!")
            setSuccess("")
        } else if (password != repassword) {
            setError("Fjalëkalimet nuk përputhen!");
            setSuccess("");
        } else {
            try {
                let updatePassword = await axios.post("https://api.foodsafe.community/api/v1/general/forgot-password/change-password", bodyToSend);
                setHideButton(true);
                if (updatePassword.data.close) {
                    setSuccess("Fjalëkalimi është ndryshuar me sukses!");
                    setError("");
                    setTimeout(() => {
                        window.close();
                    }, 2000)
                } else {
                    setSuccess("Fjalëkalimi është ndryshuar me sukses!");
                    setError("");
                    setTimeout(() => {
                        window.location.href = updatePassword.data.link;
                    }, 2000)
                }
            } catch (err) {
                setError("Diçka nuk shkoi ashtu si duhet, ju lutem provoni përsëri!");
                setSuccess("");
            }
        }
    }
    return (
            <div className="row">
                <div className="col-md-4 form__container">
                    <img src={Logo} className="logo__image"/>
                    <form onSubmit={handleSubmit}>
                        <div className="input__group">
                            <i class="fas fa-lock"></i>
                            <input type="password"
                                   name="password"
                                   className="password__input"
                                   placeholder="Fjalëkalimi i ri"/>
                        </div>
                        <div className="input__group">
                            <i class="fas fa-lock"></i>
                            <input type="password"
                                   name="repassword"
                                   className="password__input"
                                   placeholder="Shkruajeni përsëri"/>
                        </div>
                        <div className="submit__align">
                            {hideButton ? "" : <input type="submit"
                                                      value="Ruaj"
                                                      className="submit__button"/>}
                        </div>
                        <div className="success__text">{success}</div>
                        <div className="error__text">{error}</div>
                    </form>
                </div>
            </div>
    )
}

export default ForgotPassword;