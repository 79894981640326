import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";

function App() {


  return (
    <div className="container">
      <Router>
        <Switch>
          <Route path="/forgot-password/:token" component={ForgotPassword}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
